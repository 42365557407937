import React from 'react'

const Gambit = () => {
    return(
        <div>
            <h1>Welcome to the Guardians Central Gambit Page</h1>
        </div>
    );
}

export default Gambit