import React from 'react'

const Vanguard = () => {
    return(
        <div>
            <h1>Welcome to the Guardians Central Vanguard Page</h1>
        </div>
    );
}

export default Vanguard