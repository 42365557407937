import React from 'react'

const Crucible = () => {
    return(
        <div>
            <h1>Welcome to the Guardians Central Crucible Page</h1>
        </div>
    );
}

export default Crucible